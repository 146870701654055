import { Button } from "@chakra-ui/react"
import { Link } from "gatsby"
import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
//@ts-ignore
import example from "./example.png"

const SecondPage = () => (
  <Layout>
    <SEO title="Kwitantie voorbeeld pdf" />
    <div className="page content">
      <h1>Kwitantie voorbeeld pdf</h1>
      <div>
        {" "}
        <a href="/kwitantie-voorbeeld.pdf">
          <img
            src={example}
            style={{
              width: "15rem",
              aspectRatio: "24 / 35",
              boxShadow: "0 25px 50px -12px rgba(0, 0, 0, 0.25)",
              margin: "1rem 0 2rem 0",
            }}
          />
        </a>
        <p>Voorbeeld kwitantie in pdf</p>
      </div>

      <Link to="/" style={{ fontFamily: "Lato" }}>
        <Button variantColor="teal" size="lg">
          Aan de slag: maak zelf een kwitantie.{" "}
        </Button>
      </Link>
    </div>
  </Layout>
)

export default SecondPage
